import { createSlice } from '@reduxjs/toolkit';
import { Merchandise } from '../merchandise/slice';
import { sliceNames } from '../config';

export interface Genre {
    id: number;
    name: string;
    nameEn: string;
    enabled?: boolean;
    order?: number;
    createdAt?: Date;
    updatedAt?: Date;
    totalCases?: number;
    filteredMerchandises?: Merchandise[];
}

export function initialGenre(id?: number): Genre {
    return {
        id: id || -1,
        name: '',
        nameEn: '',
        enabled: false,
    };
}

interface GenreStore {
    genres: Genre[];
    activeGenres: Genre[];
    total: number;
    selected: Genre;
}

function initialGenreStore(): GenreStore {
    return {
        genres: [],
        activeGenres: [],
        total: 0,
        selected: initialGenre(),
    };
}

export const genreSlice = createSlice({
    name: sliceNames.GENRE,
    initialState: initialGenreStore(),
    reducers: {
        setAllGenres: (state, action) => {
            state.genres = action.payload;
        },
        setActiveGenres: (state, action) => {
            state.activeGenres = action.payload;
        },
    },
});

export const { setAllGenres, setActiveGenres } = genreSlice.actions;
