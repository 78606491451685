import { validateContactNumber } from '@/utils/contactNumberValidator';
import { validateEmail } from '@/utils/emailValidator';
import { validatePassword } from '@/utils/passwordValidator';
import { AnyAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get, isArray, isString, set } from 'lodash';
import { initialUser } from '../auth/authSlice';
import { RootState } from '../rootReducer';
import { User } from '../user/slice';
import { Contact, initialContact } from '../enterprise/slice';
import { MSG_DISPLAY } from '@/utils/msgDisplay';
import { validateZipCode } from '@/utils/zipcodeValidator';
import { HYDRATE } from 'next-redux-wrapper';
import { regex } from '@/utils/constants';
import { sliceNames } from '../config';

export enum UserStatus {
    AWAITING = 'Awaiting',
    REVIEW_OK = 'ReviewOK',
    REVIEW_NG = 'ReviewNG',
}
export enum UserType {
    REGULAR = 'RegularMembership',
    SPECIAL = 'SpecialMembership',
}

export type ViewerStatus = {
    id: number;
    name: UserStatus;
};
export type ViewerType = {
    id: number;
    name: UserType;
};

export interface Viewer {
    id?: number;
    contact: Contact;
    department: string;
    post: string;
    zipCode: string;
    user: User;
    prefecture?: string;
    city?: string;
    status?: ViewerStatus;
    classification?: ViewerType;
    address?: string;
    assetHeld?: string;
    memo?: string;
    createdAt?: Date;
}

export function initialViewer(id?: number): Viewer {
    return {
        id: id || -1,
        user: initialUser(),
        contact: initialContact(),
        department: '',
        post: '',
        prefecture: '',
        city: '',
        address: '',
        assetHeld: '',
        zipCode: '-',
    };
}

export interface SelectedViewer {
    viewer: Viewer;
    errors: Viewer;
}

export interface ViewerStore {
    viewers: Viewer[];
    selected: SelectedViewer;
}

function initialViewerStore(): ViewerStore {
    return {
        viewers: [],
        selected: {
            viewer: initialViewer(),
            errors: initialViewer(),
        },
    };
}

export enum SCOPE {
    CREATE = 'create',
    UPDATE = 'update',
    ALL = 'all',
}

type RequiredFieldsType = {
    name: string;
    message: string;
    scopes: SCOPE[];
};

const viewerRequiredFields: RequiredFieldsType[] = [
    { name: 'contact.firstName', message: MSG_DISPLAY.requiredErr, scopes: [SCOPE.ALL] },
    { name: 'contact.firstKana', message: MSG_DISPLAY.requiredErr, scopes: [SCOPE.ALL] },
    { name: 'contact.secondName', message: MSG_DISPLAY.requiredErr, scopes: [SCOPE.ALL] },
    { name: 'contact.secondNameKana', message: MSG_DISPLAY.requiredErr, scopes: [SCOPE.ALL] },
    { name: 'user.email', message: MSG_DISPLAY.requiredErr, scopes: [SCOPE.ALL] },
    { name: 'user.confirmEmail', message: MSG_DISPLAY.requiredErr, scopes: [SCOPE.ALL] },
    { name: 'user.password', message: MSG_DISPLAY.requiredErr, scopes: [SCOPE.CREATE] },
    { name: 'user.confirmPassword', message: MSG_DISPLAY.requiredErr, scopes: [SCOPE.CREATE] },
    { name: 'user.contactNumber', message: MSG_DISPLAY.requiredErr, scopes: [SCOPE.ALL] },
];

const viewerOptionalFields = [
    { name: 'zipCode', message: MSG_DISPLAY.requiredErr, scopes: [SCOPE.ALL] },
    { name: 'user.password', message: MSG_DISPLAY.requiredErr, scopes: [SCOPE.UPDATE] },
    { name: 'user.confirmPassword', message: MSG_DISPLAY.requiredErr, scopes: [SCOPE.UPDATE] },
];

export const validateViewerAsync = createAsyncThunk<any, SCOPE>(
    `${sliceNames.VIEWER}/validateAsync`,
    async (arg, { getState, rejectWithValue, fulfillWithValue }) => {
        const state = getState() as RootState;
        const { viewer } = state.viewerStore.selected;

        const requireFieldsToValidate = viewerRequiredFields.filter(
            (field) => field.scopes.includes(arg) || field.scopes.includes(SCOPE.ALL)
        );

        const hasRequiredError = requireFieldsToValidate.some((field: any) => {
            const value = get(viewer, field.name);
            if (!value || (isString(value) && value.trim() === '') || (isArray(value) && value.length == 0)) {
                return true;
            }
            if ((field.name === 'contact.firstName' || field.name === 'contact.secondName') && value) {
                if (!regex.nameFieldCheck.test(String(value))) {
                    return true;
                }
            }
            if ((field.name === 'contact.firstKana' || field.name === 'contact.secondNameKana') && value) {
                if (!regex.katakanaFieldCheck.test(String(value))) {
                    return true;
                }
            }
            if (field.name === 'user.email' && value) {
                const validation = validateEmail(value);
                if (!validation) {
                    return true;
                }
            }
            if (field.name === 'user.confirmEmail' && value) {
                if (viewer.user.email !== value) {
                    return true;
                }
            }
            if (field.name === 'user.password' && value) {
                const validation = validatePassword(value);
                if (!validation) {
                    return true;
                }
            }
            if (field.name === 'user.confirmPassword' && value) {
                if (viewer.user.password !== value) {
                    return true;
                }
            }
            if (field.name === 'user.contactNumber') {
                const validation = validateContactNumber(value);
                if (!validation) {
                    return true;
                }
            } else {
                return false;
            }
        });

        const optionalFieldsToValidate = viewerOptionalFields.filter(
            (field) => field.scopes.includes(arg) || field.scopes.includes(SCOPE.ALL)
        );

        const hasOptionalError = optionalFieldsToValidate.some((field: any) => {
            const value = get(viewer, field.name);
            if (field.name === 'user.password' && value !== undefined) {
                const validation = validatePassword(value);
                if (!validation) {
                    return true;
                }
            }
            if (field.name === 'user.confirmPassword' && value !== undefined) {
                if (viewer.user.password !== value) {
                    return true;
                }
            }
            if (field.name === 'zipCode' && value !== '-') {
                const validation = validateZipCode(value);
                if (!validation) {
                    return true;
                }
            } else {
                return false;
            }
        });
        if (hasRequiredError || hasOptionalError) return rejectWithValue(MSG_DISPLAY.sthWentWrong);
        // return fulfillWithValue('No Error');
    }
);

export const setViewerFieldErrorAsync = createAsyncThunk<any, any, any>(
    `${sliceNames.VIEWER}/setFieldError`,
    async (arg, { fulfillWithValue }) => {
        return fulfillWithValue(arg);
    }
);

export const viewerSlice = createSlice({
    name: sliceNames.VIEWER,
    initialState: initialViewerStore(),
    reducers: {
        setAllViewers: (state, action) => {
            state.viewers = action.payload;
        },
        setSelectedViewer: (state, action) => {
            state.selected.viewer = action.payload;
        },
        updateViewerDetails: (state, action) => {
            set(state.selected.viewer, action.payload.name, action.payload.value);
        },
        validateViewer: (state, action) => {
            set(state.selected.errors, action.payload, '');
        },
        resetViewer: (state) => {
            state.selected.viewer = initialViewer();
            state.selected.errors = initialViewer();
        },
        resetViewerErrors: (state) => {
            state.selected.errors = initialViewer();
        },
        deleteSelectedViewer: (state) => {
            state.viewers = state.viewers.filter((viewer) => viewer.id !== state.selected.viewer.id);
            state.selected.viewer = initialViewer();
        },
        addServerSideErrors: (state, action) => {
            set(state.selected.errors, action.payload.name, action.payload.value);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(validateViewerAsync.rejected, (state) => {
            viewerRequiredFields.forEach((field) => {
                const value = get(state.selected.viewer, field.name);
                if (
                    (value !== undefined && !value) ||
                    (isString(value) && value.trim() === '') ||
                    (isArray(value) && value.length == 0)
                ) {
                    set(state.selected.errors, field.name, field.message);
                }
                if ((field.name === 'contact.firstName' || field.name === 'contact.secondName') && value) {
                    if (!regex.nameFieldCheck.test(String(value))) {
                        set(state.selected.errors, field.name, field.message);
                    }
                }
                if ((field.name == 'contact.firstKana' || field.name == 'contact.secondNameKana') && value) {
                    if (!regex.katakanaFieldCheck.test(String(value))) {
                        set(state.selected.errors, field.name, field.message);
                    }
                }
                if (field.name === 'user.email' && value) {
                    const validation = validateEmail(value);
                    if (!validation) {
                        set(state.selected.errors, field.name, field.message);
                    }
                }
                if (field.name === 'user.confirmEmail' && value) {
                    if (state.selected.viewer.user.email !== value) {
                        set(state.selected.errors, field.name, field.message);
                    }
                }
                if (field.name === 'user.password' && value) {
                    const validation = validatePassword(value);
                    if (!validation) {
                        set(state.selected.errors, field.name, field.message);
                    }
                }
                if (field.name === 'user.confirmPassword') {
                    if (state.selected.viewer.user.password !== value) {
                        set(state.selected.errors, field.name, field.message);
                    }
                }
                if (field.name === 'user.contactNumber') {
                    const validation = validateContactNumber(value);
                    if (!validation) {
                        set(state.selected.errors, field.name, field.message);
                    }
                }
            });
            viewerOptionalFields.forEach((field) => {
                const value = get(state.selected.viewer, field.name);
                if (field.name === 'zipCode' && value !== '-') {
                    const validation = validateZipCode(value);
                    if (!validation) {
                        set(state.selected.errors, field.name, field.message);
                    }
                }
            });
        });
        builder.addCase(setViewerFieldErrorAsync.fulfilled, (state, action) => {
            set(state.selected.errors, action.payload.name, action.payload.value);
        });
        builder.addCase(HYDRATE, (state, action: AnyAction) => {
            const nextState = {
                ...state,
                ...action.payload.viewerStore,
            };
            return nextState;
        });
    },
});

export const {
    setAllViewers,
    setSelectedViewer,
    updateViewerDetails,
    validateViewer,
    resetViewer,
    resetViewerErrors,
    deleteSelectedViewer,
    addServerSideErrors,
} = viewerSlice.actions;
