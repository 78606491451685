import { rootReducer } from './rootReducer';
import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from '@/redux/api/apiSlice';
import { createWrapper } from 'next-redux-wrapper';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { nextReduxCookieMiddleware, wrapMakeStore } from 'next-redux-cookie-wrapper';
import { persistConfig, AuthCookieConfig } from './config';
import { apiV2Slice } from './api/apiV2Slice';

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
            .concat(apiSlice.middleware, apiV2Slice.middleware)
            .prepend(
                nextReduxCookieMiddleware({
                    subtrees: [AuthCookieConfig],
                })
            ),
    devTools: true,
});

const makeStore = wrapMakeStore(() => store);

export type AppStore = ReturnType<typeof makeStore>;
export type AppDispatch = AppStore['dispatch'];

export const reduxWrapper = createWrapper<AppStore>(makeStore, { debug: false });
export const persistor = persistStore(store);
export default store;
