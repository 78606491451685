import { regex } from './constants';

const whiteListedDomains = ['bjitgroup.com', 'vectorinc.co.jp'];

export function validateEmail(email: string) {
    if (regex.emailValidate.test(email)) {
        const domain = email.split('@')[1];
        return !email.includes('+') || whiteListedDomains.includes(domain);
    }
    return false;
}
