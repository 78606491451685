import { CustomArrowProps } from 'react-slick';

export const playBackRates = Array.from({ length: 3 }, (_, idx) => 1 + idx * 0.25);

export const mobileBreakPoint = 767.95;

export enum FormSteps {
    STEP_ZERO = 0,
    STEP_ONE = 1,
    STEP_TWO = 2,
}

export interface CarouselArrowProps extends CustomArrowProps {
    prev?: boolean;
}

export const cardSliderInfo = {
    PC: { slideLimit: 5, slideWidth: 240, gap: 2 },
    SP: { slideLimit: 3, slideWidth: 106, gap: 5 },
};

export const tabStepData = ['入力', '確認', '登録完了'];
export const passwordMinLength = 8;

export const regex = {
    nameFieldCheck: /^[^!@#$%^&*()_+={}\[\]\\|:;"'<>,.?/~`]+$/,
    katakanaFieldCheck: /^[\u30A1-\u30F4\u30F7-\u30FA\u30FD\u30FE\uFF66-\uFF9Dー]+$/u,
    contactNumberPattern: /^\w+-\w+-\w+$/,
    contactNumberLength: /^\d{10,11}$/,
    contactFindHyphen: /-/g,
    findKataKana: (data: any) => new RegExp('(' + Object.keys(data).join('|') + ')', 'g'),
    kanaReplace1: /ﾞ/g,
    kanaReplace2: /ﾟ/g,
    emailValidate: /^([a-z0-9\+_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,6}$/i,
    passwordValidate: new RegExp(`^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d!-~]{${passwordMinLength},}$`),
    emojiValidate:
        /([\u2700-\u27BF]|[\u{1F650}-\u{1F67F}]|[\u{1F600}-\u{1F64F}]|[\u2600-\u26FF]|[\u{1F300}-\u{1F5FF}]|[\u{1F900}-\u{1F9FF}]|[\u{1FA70}-\u{1FAFF}]|[\u{1F680}-\u{1F6FF}])/u,
    directionValidate: /^(?!.*[\u200F\u202B\u202E\u2067]).*$/s,
    urlCheck: new RegExp("https?://[\\w!#$&'*+,-./:;=?@~]+", 'g'),
    emailCheck: /[\w-.]+@([\w-]+\.)+[\w-]{2,20}/g,
    phoneCheck: /((\+)?(\d{1,3})?[ .-]?\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4}))/g,
    clickableReplace: /[^\d+]/g,
    phoneReplace: /(\d{3})[ .-]?(\d{3})[ .-]?(\d{4})/,
    zipCodeCheck: /^\d{3}-\d{4}$/,
    numberCheck: /^[0-9\b]+$/,
    urlPrevent:
        /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
    phonePrevent: /\d{9}/,
    emailPrevent: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    priceCommaReplace: /,/g,
    priceDigitReplace: /[^\d]/,
    halfWidthFullWidthNumber: /^[0-9０-９]*$/,
};

export const mbCharStringLength = (str: string) => [...str].length;

export const appendPopularCarouselOnRow = 1;
