import { SubtreeConfig } from 'next-redux-cookie-wrapper';
import { PersistConfig, WebStorage } from 'redux-persist';
import { RootState } from './rootReducer';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';

export enum sliceNames {
    API = 'api',
    API_V2 = 'api_v2',
    AUTH = 'auth',
    ENTERPRISE = 'enterprise',
    GENRE = 'genre',
    MERCHANDISE = 'merchandise',
    VIEWER = 'viewer',
}

function createPersistStorage(): WebStorage {
    if (typeof window !== 'undefined') return createWebStorage('local');
    return {
        getItem(_key) {
            return Promise.resolve(null);
        },
        setItem(_key, _item) {
            return Promise.resolve();
        },
        removeItem(_key) {
            return Promise.resolve();
        },
    };
}

export const persistConfig: PersistConfig<RootState> = {
    key: `vmtv-public-${process.env.NEXT_PUBLIC_ENVIRONMENT}`,
    version: 1,
    storage: createPersistStorage(),
    blacklist: [sliceNames.API, sliceNames.API_V2, sliceNames.AUTH],
};

export const AuthCookieConfig: SubtreeConfig = {
    subtree: sliceNames.AUTH,
    cookieName: `vmtv-public-${process.env.NEXT_PUBLIC_ENVIRONMENT}-auth`,
    compress: false,
};
