import { FileDimension } from './file-validator';

export const MSG_DISPLAY = {
    requiredErr: '入力内容を確認してください',
    sthWentWrong: '問題が発生しました',
    imgDimensionErr: ({ minWidth, minHeight }: Pick<FileDimension, 'minHeight' | 'minWidth'>) =>
        `画像は横${minWidth}px以上、縦${minHeight}px以上のファイルをアップロードしてください。`,
    fileLoadErr: `指定されたファイルのロードに失敗しました。`,
    fileMaxSizeErr: (size: string) => `Error: Maximum allowed size is ${size}`,
    fileUploadToast: 'ファイルのアップロードには時間がかかる場合があります。お待ちください。',
    updateToast: '更新しました',
    inquirySentSuccess: 'お問合せ送信完了',
    successMerchandiseCreate: '作った商品',
    successPasswordReset: 'パスワードが正常にリセットされました',
};
