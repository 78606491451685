import { ReactElement, ReactNode } from 'react';
import type { AppProps } from 'next/app';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, reduxWrapper } from '@/redux/store';
import { ToastContainer } from 'react-toastify';
import { NextPage } from 'next';
import RenderGate from '@/components/common/render-gate';
import 'react-toastify/dist/ReactToastify.css';
import '@/styles/index.scss';
import '@/components/toasts/body/toast-body.scss';
import '@/components/toasts/toasts.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
    getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
};

export default function App({ Component, pageProps, ...rest }: AppPropsWithLayout) {
    const { store, props } = reduxWrapper.useWrappedStore(pageProps);
    const getLayout = Component.getLayout ?? ((page) => page);

    return (
        <Provider store={store}>
            <RenderGate>
                <PersistGate loading={null} persistor={persistor}>
                    {getLayout(<Component {...props} {...rest} />)}
                </PersistGate>
                <ToastContainer limit={2} autoClose={2000} />
            </RenderGate>
        </Provider>
    );
}
