import { apiSlice } from './api/apiSlice';
import { combineReducers } from '@reduxjs/toolkit';
import { authSlice } from './auth/authSlice';
import { genreSlice } from './genre/slice';
import { viewerSlice } from './viewer/slice';
import { merchandiseSlice } from './merchandise/slice';
import { enterpriseSlice } from './enterprise/slice';
import { apiV2Slice } from './api/apiV2Slice';

export const rootReducer = combineReducers({
    [apiSlice.reducerPath]: apiSlice.reducer,
    [apiV2Slice.reducerPath]: apiV2Slice.reducer,
    [authSlice.name]: authSlice.reducer,
    enterpriseStore: enterpriseSlice.reducer,
    viewerStore: viewerSlice.reducer,
    genreStore: genreSlice.reducer,
    merchandiseStore: merchandiseSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
