import { AnyAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Enterprise } from '../enterprise/slice';
import { Genre } from '../genre/slice';
import { set } from 'lodash';
import { HYDRATE } from 'next-redux-wrapper';
import { sliceNames } from '../config';

export interface Status {
    id?: number;
    value: Date;
    name: string;
}

export interface MediaOriginalFileName {
    logoPath?: string;
    videoPath?: string;
    horizontalThumbPath?: string;
    verticalThumbPath?: string;
    presentationFilePath?: string;
}

export interface Media {
    id?: number;
    basePath?: string;
    logoPath?: File | string;
    videoPath: File | string;
    playbackSpeed?: number;
    horizontalThumbPath: File | string;
    verticalThumbPath: File | string;
    originalFileName?: MediaOriginalFileName;
}

export interface Merchandise {
    id?: number;
    title: string;
    description: string;
    price: string;
    priceVisibility: number;
    status?: Status;
    memo?: string;
    media: Media;
    enterprise?: Enterprise;
    genres?: Genre[];
    isFavourite?: boolean | null;
    isNew?: boolean;
    isPopular?: boolean;
}

export interface SelectedMerchandise {
    merchandise: Merchandise;
    errors: Merchandise;
}

export interface MerchandiseStore {
    merchandises: Merchandise[];
    selected: SelectedMerchandise;
}

export function initialMedia(): Media {
    return {
        basePath: '',
        videoPath: '',
        playbackSpeed: 1,
        horizontalThumbPath: '',
        verticalThumbPath: '',
    };
}

export function initialMerchandise(id?: number): Merchandise {
    return {
        id: id || -1,
        title: '',
        description: '',
        price: '',
        priceVisibility: 1,
        genres: [],
        media: initialMedia(),
    };
}

function initailMerchandiseStore(): MerchandiseStore {
    return {
        merchandises: [],
        selected: {
            merchandise: initialMerchandise(),
            errors: initialMerchandise(),
        },
    };
}

export const setFieldErrorAsync = createAsyncThunk<any, any, any>(
    `${sliceNames.MERCHANDISE}/setFieldError`,
    async (arg, { fulfillWithValue }) => {
        return fulfillWithValue(arg);
    }
);

export const merchandiseSlice = createSlice({
    name: sliceNames.MERCHANDISE,
    initialState: initailMerchandiseStore(),
    reducers: {
        setSelectedMerchandise: (state, action) => {
            state.selected.merchandise = action.payload;
        },
        updateMerchandiseDetails: (state, action) => {
            if (action.payload.name.includes('genres')) {
                if (state.selected.merchandise.genres) {
                    state.selected.merchandise.genres[0] = action.payload.value;
                }
            } else {
                set(state.selected.merchandise, action.payload.name, action.payload.value);
            }
        },
        resetMerchandise: (state) => {
            state.selected.merchandise = initialMerchandise();
            state.selected.errors = initialMerchandise();
        },
        validateMerchandise: (state, action) => {
            set(state.selected.errors, action.payload, '');
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setFieldErrorAsync.fulfilled, (state, action) => {
            set(state.selected.errors, action.payload.name, action.payload.value);
        });
        builder.addCase(HYDRATE, (state, action: AnyAction) => {
            const nextState = {
                ...state,
                ...action.payload.merchandiseStore,
            };
            return nextState;
        });
    },
});

export const { updateMerchandiseDetails, resetMerchandise, validateMerchandise, setSelectedMerchandise } =
    merchandiseSlice.actions;
