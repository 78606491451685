import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HYDRATE } from 'next-redux-wrapper';
import { setInitialLoggedInToken, setInitialLoggedInUser } from '../auth/authSlice';
import HTTP_STATUS from '@/utils/httpStatus';
import { sliceNames } from '../config';

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API_URL,
    prepareHeaders: (headers, { getState }) => {
        const state: any = getState();
        headers.set('authorization', `Bearer ${state.auth.loggedInToken}`);
        headers.set('lang', 'ja');
        return headers;
    },
});

export const apiSlice = createApi({
    reducerPath: sliceNames.API,
    baseQuery: async (args, api, extraOptions) => {
        let result: any = await baseQuery(args, api, extraOptions);
        if (result.error?.status === HTTP_STATUS.UNAUTHORIZED) {
            api.dispatch(setInitialLoggedInToken());
            api.dispatch(setInitialLoggedInUser());
        }
        return result;
    },
    extractRehydrationInfo(action, { reducerPath }) {
        if (action.type === HYDRATE) {
            return action.payload[reducerPath];
        }
    },
    refetchOnMountOrArgChange: true,
    tagTypes: ['Enterprise', 'Merchandise', 'Genre', 'Active-Genres', 'Viewer', 'Inquiries', 'Carousel'],
    endpoints: (builder) => ({}),
});

export type API_RESPONSE<T = any> = { message: string } & (
    | {
          success: true;
          result: T;
      }
    | {
          success: false;
          errors?: Record<string, any>;
      }
);
