import { AnyAction, createSlice } from '@reduxjs/toolkit';
import { Role, Roles, User } from '../user/slice';
import { HYDRATE } from 'next-redux-wrapper';
import { Contact, initialContact } from '../enterprise/slice';
import { sliceNames } from '../config';

export interface AuthState {
    loggedInUser: User;
    loggedInToken: string;
    contact?: Contact;
}

export interface ResetPassword {
    email: string;
    token: string;
    password: string;
    passwordConfirmation: string;
}

export function initialResetPasswordData() {
    return {
        email: '',
        token: '',
        password: '',
        passwordConfirmation: '',
    };
}

export function initialRoles(id?: number): Roles {
    return {
        id: id || -1,
        title: Role.GUEST,
    };
}

export function initialUser(id?: number): User {
    return {
        id: id || -1,
        name: '',
        email: '',
        password: '',
        confirmEmail: '',
        confirmPassword: '',
        corporateName: '',
        contactNumber: '--',
        roles: [initialRoles()],
    };
}

export const initialState: AuthState = {
    loggedInUser: initialUser(),
    loggedInToken: '',
};

export const authSlice = createSlice({
    name: sliceNames.AUTH,
    initialState,
    reducers: {
        setLoggedInUser: (state, action) => {
            state.loggedInUser = action.payload;
        },
        setLoggedInToken: (state, action) => {
            state.loggedInToken = action.payload;
        },
        setInitialLoggedInUser: (state) => {
            state.loggedInUser = initialUser();
        },
        setInitialLoggedInToken: (state) => {
            state.loggedInToken = '';
        },
        setLoggedInUserContact: (state, action) => {
            state.contact = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(HYDRATE, (state, action: AnyAction) => {
            const nextState = {
                ...state,
                ...action.payload.auth,
            };
            return nextState;
        });
    },
});

export const {
    setLoggedInUser,
    setLoggedInToken,
    setInitialLoggedInToken,
    setInitialLoggedInUser,
    setLoggedInUserContact,
} = authSlice.actions;
