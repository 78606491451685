export enum Role {
    ADMIN = 'admin',
    GUEST = 'guest',
    VIEWING_USER = 'member',
    ENTERPRISE = 'enterprise',
    ENTERPRISE_MANAGER = 'enterprise_manager',
    ENTERPRISE_SUPERVISOR = 'enterprise_supervisor',
}

export interface Roles {
    id: number;
    title: Role;
}

export interface User {
    id?: number;
    name: string;
    email?: string;
    confirmEmail?: string;
    profilePhotoPath?: string;
    publishedAt?: Date;
    createdAt?: Date;
    roles?: Roles[];
    updatedAt?: Date;
    corporateName: string;
    hasPassword?: boolean;
    password?: string;
    confirmPassword?: string;
    contactNumber?: string;
    certified?: boolean;
    basePath?: string;
}
