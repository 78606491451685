import { PropsWithChildren, useEffect, useState } from 'react';

export interface RenderGateProps extends PropsWithChildren {}

export default function RenderGate({ children }: RenderGateProps) {
    const [showPage, setShowPage] = useState(false);
    useEffect(() => {
        setShowPage(true);
    }, []);

    if (!showPage) {
        return null;
    }

    return <>{children}</>;
}
