import { HYDRATE } from 'next-redux-wrapper';
import { Media, initialMedia } from '../merchandise/slice';
import { User } from '../user/slice';
import { AnyAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { initialUser } from '../auth/authSlice';
import { set } from 'lodash';
import { sliceNames } from '../config';

export interface Contact {
    firstName: string;
    secondName: string;
    firstKana: string;
    secondNameKana: string;
}

export interface Enterprise {
    id?: number;
    user: User;
    contact: Contact;
    media: Media;
    department?: string;
    post?: string;
    corporateUrl?: string;
    zipCode: string;
    prefecture?: string;
    city?: string;
    address?: string;
    memo?: string;
}

export interface SelectedEnterprise {
    enterprise: Enterprise;
    errors: Enterprise;
}

export interface EnterpriseStore {
    enterprises: Enterprise[];
    selected: SelectedEnterprise;
}

export function initialContact(): Contact {
    return {
        firstName: '',
        secondName: '',
        firstKana: '',
        secondNameKana: '',
    };
}

export function initialEnterprise(id?: number): Enterprise {
    return {
        id: id || -1,
        user: initialUser(),
        contact: initialContact(),
        media: initialMedia(),
        department: '',
        post: '',
        corporateUrl: '',
        zipCode: '-',
        prefecture: '',
        city: '',
        address: '',
        memo: '',
    };
}

function initialEnterpriseStore(): EnterpriseStore {
    return {
        enterprises: [],
        selected: {
            enterprise: initialEnterprise(),
            errors: initialEnterprise(),
        },
    };
}

export const setEnterpriseFieldErrorAsync = createAsyncThunk<any, any, any>(
    `${sliceNames.ENTERPRISE}/setFieldError`,
    async (arg, { fulfillWithValue }) => {
        return fulfillWithValue(arg);
    }
);

export const enterpriseSlice = createSlice({
    name: sliceNames.ENTERPRISE,
    initialState: initialEnterpriseStore(),
    reducers: {
        setSelectedEnterprise: (state, action) => {
            state.selected.enterprise = action.payload;
        },
        updateEnterpriseDetails: (state, action) => {
            set(state.selected.enterprise, action.payload.name, action.payload.value);
        },
        addServerSideErrors: (state, action) => {
            state.selected.errors = set(state.selected.errors, action.payload.name, action.payload.value);
        },
        validateEnterprise: (state, action) => {
            set(state.selected.errors, action.payload, '');
        },
        resetEnterpriseStore: (state) => {
            state = initialEnterpriseStore();
        },
        resetEnterprise: (state) => {
            state.selected.enterprise = initialEnterprise();
            state.selected.errors = initialEnterprise();
        },
        resetEnterpriseErrors: (state) => {
            state.selected.errors = initialEnterprise();
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setEnterpriseFieldErrorAsync.fulfilled, (state, action) => {
            set(state.selected.errors, action.payload.name, action.payload.value);
        });
        builder.addCase(HYDRATE, (state, action: AnyAction) => {
            const nextState = {
                ...state,
                ...action.payload.enterpriseStore,
            };
            return nextState;
        });
    },
});

export const {
    setSelectedEnterprise,
    updateEnterpriseDetails,
    validateEnterprise,
    resetEnterpriseStore,
    resetEnterprise,
    resetEnterpriseErrors,
    addServerSideErrors,
} = enterpriseSlice.actions;
